<template>
  <v-card :loading="submitImage" class="mx-auto">
    <v-img src="https://cdn.vuetifyjs.com/images/cards/cooking.png" v-if="!cover"></v-img>

    <v-img :src="cover" v-else></v-img>

    <v-card-subtitle class="red--text text-caption text-center">If you don't see the cover in this section, please change the cover manually via the button below</v-card-subtitle>

    <div class="change-picture px-3 mt-3">
      <v-btn
        block
        outlined
        color="primary"
        :disabled="submitImage"
        @click="onButtonClick"
      >
        Change Book Cover
      </v-btn>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChanged"
      />
    </div>

    <v-card-text>
      <div class="text-caption">
        Besar file: maksimum 10.000.000 bytes (10 Megabytes). Ekstensi file yang
        diperbolehkan: .JPG .JPEG .PNG.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  props: ["cover", "qrcbn"],
  data() {
    return {
      isSelecting: false,
      submitImage: false,
    };
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.submitImage = true;

      var fm = new FormData();
      fm.append("qrcbn", this.qrcbn);
      fm.append("image", this.selectedFile);

      axios
        .post("updateCover", fm, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.data = response.data.data.user;
          this.$emit("updated-data");
        })
        .catch((error) => console.log(error))
        .finally(() => (this.submitImage = false));
    },
  },
};
</script>
