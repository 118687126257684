<template>
  <div id="main-container">
    <!-- Hero -->
    <div class="bg-body-light">
      <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2">
          <div class="flex-grow-1">
            <h1 class="h3 fw-bold mb-2">
              Edit Naskah
            </h1>
            <h2 class="fs-base lh-base fw-medium text-muted mb-0">
              Ubah data naskah <strong>{{naskah.title}}</strong>.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- END Hero -->
    <div class="content">
      <div class="row">
        <div class="col-12 col-md-3">
          <cover-update :cover="naskah.cover_url" :qrcbn="naskah.qrcbn_id" @updated-data="getData" />
        </div>
        <div class="col-12 col-md-9">
          <edit-form :title="naskah.title" :author="naskah.author" :description="naskah.description"
            :edisi="naskah.edisi" :seri="naskah.seri" :publish_date="naskah.publish_date" :page="naskah.page"
            :readers="naskah.readers" :category="naskah.category" :publish_type="naskah.publish_type" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoverUpdate from "@/components/dashboard/EditNaskah/CoverUpdate.vue";
import EditForm from "@/components/dashboard/EditNaskah/Form.vue";
import axios from "axios";
export default {
  components: {
    EditForm,
    CoverUpdate,
  },
  data() {
    return {
      naskah: [],
    };
  },
  methods: {
    getData() {
      this.loading_data = true;
      var id = this.$route.params.id;

      axios
        .get("naskah/" + id, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.naskah = response.data.data.naskah;
        })
        .catch((error) => {
          console.log(error);
          this.loading_data = false;
        })
        .finally(() => (this.loading_data = false));
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
@import '../../../public/oneui/assets/css/oneui.min.css';
</style>
