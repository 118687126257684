<template>
  <div>
    <form @submit.prevent="postNaskah">
      <div class="con-form">
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="mb-4">
              <label class="form-label" for="example-text-input-alt">Judul</label>
              <input type="text" class="form-control form-control-alt" placeholder="Judul Naskah" v-model="title">
            </div>
          </div>

          <div class="col-12 col-md-12">
            <div class="mb-4">
              <label class="form-label" for="example-text-input-alt">Kepengarangan</label>
              <input type="text" class="form-control form-control-alt"
                placeholder="Susunan Pengarang = Penulis, Editor, Pembuat Sampul" v-model="author">
            </div>
          </div>

          <div class="col-12 col-md-12">
            <div class="mb-4">
              <label class="form-label" for="example-text-input-alt">Sinopsis</label>
              <textarea class="form-control form-control-alt" placeholder="Deskripsi Buku / Sinopsis"
                v-model="description" rows="5"></textarea>
            </div>
          </div>

          <div class="col-12 col-md-2">
            <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Edisi</label>
                <input type="text" class="form-control form-control-alt" placeholder="Edisi Terbit"
                  v-model="edisi">
              </div>
          </div>

          <div class="col-12 col-md-2">
            <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Seri</label>
                <input type="text" class="form-control form-control-alt" placeholder="Nomor Seri" v-model="seri">
              </div>
          </div>

          <div class="col-12 col-md-4">
            <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Tanggal Terbit</label>
                <input type="date" class="form-control form-control-alt" placeholder="Jumlah Halaman"
                  v-model="publish_date">
              </div>
          </div>

          <div class="col-12 col-md-4">
            <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Jumlah Halaman</label>
                <input type="text" class="form-control form-control-alt" placeholder="Jumlah Halaman"
                  v-model="page">
              </div>
          </div>
        </div>

        <div class="form-title mb-2">
          <b><i>Jenis Buku</i></b>
        </div>
        <hr />

        <div class="row">
          <div class="col-12 col-md-12">
            <div class="jenis-title mb-3"><b>Kelompok Usia Pembaca</b></div>
          </div>
          <div class="col-12 col-md-12">
            <div class="jenis-radio mb-3 d-flex">
              <vs-radio v-model="readers" val="anak">Anak - Anak</vs-radio>
              <vs-radio v-model="readers" val="dewasa">Dewasa</vs-radio>
              <vs-radio v-model="readers" val="semua usia">Semua Usia</vs-radio>
            </div>
          </div>

          <div class="col-12 col-md-4">
            <div class="jenis-title mb-3"><b>Kategori Buku</b></div>
          </div>
          <b-col col lg="8" md="8" cols="12" sm="12">
            <div class="jenis-radio mb-3 d-flex">
              <vs-radio v-model="category" val="fiksi">Fiksi</vs-radio>
              <vs-radio v-model="category" val="non fiksi">Non Fiksi</vs-radio>
            </div>
          </b-col>

          <b-col col lg="4" md="4" cols="12" sm="12">
            <div class="jenis-title mb-3"><b>Media Terbit</b></div>
          </b-col>
          <b-col col lg="8" md="8" cols="12" sm="12">
            <div class="jenis-radio mb-3 d-flex">
              <vs-radio v-model="publish_type" val="cetak">Buku Cetak</vs-radio>
              <vs-radio v-model="publish_type" val="ebook">Electronic Book (e-Book)</vs-radio>
            </div>
          </b-col>
        </div>

        <hr />

        <div class="text-right">
          <vs-button type="submit" class="btn btn-primary px-5" :loading="loading.submit">Save Changes</vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: [
    "title",
    "author",
    "description",
    "edisi",
    "seri",
    "publish_date",
    "page",
    "readers",
    "category",
    "publish_type",
  ],
  data: () => ({
    form: {
      title: "",
      author: "",
      description: "",
      edisi: "",
      seri: "",
      date: "",
      page: "",
      cover_url: "",
      readers: "",
      category: "",
      type: "",

      tautan: {
        platform: "",
        link: "",
      },

      links: [],
    },

    loading: {
      submit: false,
    },

    errormsg: "",
  }),

  methods: {
    saveLink() {
      if (this.form.tautan.platform == "" || this.form.tautan.link == "") {
        this.openNotification(
          "top-right",
          "danger",
          "10000",
          "Gagal",
          "Lengkapi Data Link"
        );
      } else {
        this.form.links.push({
          platform: this.form.tautan.platform,
          link: this.form.tautan.link,
        });

        this.form.tautan.platform = "";
        this.form.tautan.link = "";

        this.openNotification(
          "top-right",
          "success",
          "10000",
          "Sukses",
          "Link Berhasil Ditambahkan, Silahkan Tambahkan Link Lainnya Jika Ada"
        );
      }
    },

    postNaskah() {
      this.loading.submit = true;

      const token = localStorage.getItem("access_token");

      var formData = new FormData();

      formData.append("qrcbn", this.$route.params.id);
      formData.append("title", this.title);
      formData.append("author", this.author);
      formData.append("description", this.description);
      formData.append("edisi", this.edisi);
      formData.append("seri", this.seri);
      formData.append("page", this.page);
      formData.append("readers", this.readers);
      formData.append("category", this.category);
      formData.append("publish_type", this.publish_type);
      formData.append("publish_date", this.publish_date);

      axios
        .post("updateNaskah", formData, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          this.openNotification(
            "top-right",
            "success",
            "10000",
            "Sukses",
            response.data.message
          );
          this.$router.push({ name: "library" });
        })
        .catch((error) => {
          this.errormsg = error.response.data.error;
          console.log(this.errormsg.title);
          this.loading.submit = false;
        })
        .finally(() => (this.loading.submit = false));
    },
  },
};
</script>

<style>
.dashboard-title {
  background-color: #def5f7;
}

#sinopsis {
  font-size: 0.95em;
}

.jenis-radio {
  text-align: left !important;
}

.add_link {
  background: transparent;
  font-size: 1em;
  text-transform: uppercase;
}
</style>
